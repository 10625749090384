@import 'styles/globals.scss';

.info-block {
  @include container;
  padding-top: 50px;
  padding-bottom: 50px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-weight: 300;

    a {
      color: #2E7BB4;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}