// COLORS
$white: #FFF;
$black: #000;
$grey--light: #F7F7F7;
$grey: #767676;
$blue--light: #53AEFF;
$blue: #3173AE;
$red: #B83030;


//RESET
* {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  font-family: 'Raleway', sans-serif;
}


ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}


// MIXINS
@mixin container {
  padding: 0 calc(50% - 1200px/2);
}

// COMPONENTS
.btn {
  background: #2E7BB4;
  color: white;
  font-weight: bold;
  padding: 0 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    opacity: .6;
    cursor: auto;
  }
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}