@import 'styles/globals.scss';

.search-block {
  @include container;
  padding-top: 140px;
  padding-bottom: 120px;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.6);

  .background {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;


    img {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 40px;

    span {
      text-decoration: underline;
    }
  }

  .form {
    display: flex;
    align-items: flex-end;

    &>:not(:last-child) {
      margin-right: 20px;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      width: 260px;

      input {
        background: $white;
        margin-top: 10px;
        padding: 11px 16px;
        font-size: 16px;
      }

      &--disabled {
        opacity: 0.6;
      }
    }
  }


  .error {
    margin-top: 20px;
    height: 20px;
  }
}