@import 'styles/globals.scss';

.timeline {
  @include container;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;


  &__main-img {
    margin-bottom: 25px;

    img {
      height: 120px;
    }
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  h2 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: $grey;

    &::before,
    &::after {
      content: '';
      display: block;
      background: $grey;
      width: 25px;
      height: 2px;
      margin: 0 10px;
    }
  }

  .ports-list {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 50%;

    &--big {
      width: 80%;
    }

    .port {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      p {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 20px;
      }

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -120%);
      }
    }

    .travel {
      flex-grow: 1;
      border-top: 2px solid $blue--light;
      height: 0;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -80%);
      }
    }

    //COLOR SCHEME
    .port {
      &.incoming {
        background: $grey;
      }

      &.present {
        background: $red;
      }

      &.departed {
        background: $blue;
      }

    }

    .travel {
      &.incoming {
        border-color: $grey;
      }

      &.present {
        border-top: 2px dashed $blue--light;
      }

      &.departed {
        border-color: $blue--light;
      }
    }

    .travel.present+.port.incoming {
      background: $red;

    }
  }
}