@import 'styles/globals.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 10;
  overflow: auto;
  padding: 80px 0;
  background: rgba(0, 0, 0, .6);

  &__background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    cursor: pointer;
  }

  &__content {
    max-width: 90%;
    min-width: 60%;
    background: $white;
    border-radius: 10px;
    padding: 40px 80px;
    position: relative;
    height: fit-content;

  }

  &__close-button {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 30px;
    width: 30px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 100%;
      background: $grey;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}