@import 'styles/globals.scss';

.schedule-info {
  margin-top: 40px;
  width: 100%;

  .row {
    display: flex;
    font-weight: 100;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    .red,
    .blue {
      display: flex;

      &::before {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }

    .red {
      color: $red;

      &::before {
        background: $red;
      }
    }

    .blue {
      color: $blue;
      margin-right: 10px;

      &::before {
        background: $blue;
      }
    }

    span {
      font-size: 10px;
      margin-right: 4px;
    }

    a {
      color: $blue;
      margin: 0 4px;
    }
  }
}