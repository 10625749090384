@import 'styles/globals.scss';

.table-block {
  @include container;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bar {
    margin-bottom: 50px;
    width: 100%;
    height: 1px;
    background: $grey;

  }

  &__main-img {
    margin-bottom: 25px;

    img {
      height: 120px;
    }
  }

  h1 {

    font-size: 24px;
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: $grey;

    &::before,
    &::after {
      content: '';
      display: block;
      background: $grey;
      width: 25px;
      height: 2px;
      margin: 0 10px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th {
          padding: 18px 0;
          text-align: center;
          font-weight: 100;
          color: $grey;
        }
      }
    }

    tbody {
      tr {

        background: $grey--light;

        &:nth-child(2n) {
          background: $white;
        }

        td {
          padding: 12px 0;
          text-align: center;
          border-right: 1px solid #F0F0F0;
          border-left: 1px solid #F0F0F0;
          font-weight: 100;

          button {
            font-size: 16px;
            color: $blue--light;
            text-decoration: underline;
            cursor: pointer;
          }

          &.red {
            color: $red;
          }

          &.blue {
            color: $blue;
          }
        }
      }
    }
  }
}

.table-container {
  min-width: 1100px;
}


.download {
  align-self: flex-end;
  font-size: 13px;
  margin: 20px;
  cursor: pointer;
  color: #767676;
  img {
    width: 20px;
    margin-left: 10px;
  }
  a {
    display: flex;
    align-items: flex-end;
  }
}