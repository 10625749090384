@import "styles/globals.scss";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  main {
    flex-grow: 1;
  }

  .header {
    @include container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    img {
      height: 40px;
    }

    &__right {
      display: flex;
      align-items: center;

      .icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      p {
        margin-top: 4px;
      }
    }
  }

  .footer {
    @include container;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background: $black;
    color: $white;
    &__logo {
      img {
        height: 8vh;
        width: auto;
      }
    }
    &__contact {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      li {
        display: flex;
        font-weight: 100;

        .icon {
          margin-left: 10px;
        }

        a {
          color: white;
          display: flex;
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    &__copyright {
      width: 100%;
      text-align: center;
      padding: 40px 0 10px;
      font-weight: 100;

      a {
        color: white;
      }

      span {
        font-weight: 600;
      }
    }
  }
}
